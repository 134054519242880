import React from 'react';

// styles
import './anyone-trusted-mob.scss';

//imgs
const leftFrame = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Frame934.png';

export default function AnyoneTrustedMob({ Progress, Page }) {

    const DATA = [
        { name: 'Chartered Accountant' },
        { name: 'Designer' },
        { name: 'Doctor' },
        { name: 'Babysitter' },
        { name: 'Lawyer' },
        { name: 'School' },
        { name: 'Connect' },
        { name: '' },

    ]

    return (
        <div className="anyone-trusted-mob-main-container"
            style={{
                opacity: Page < 2 && `0`,
                transform: Page >= 3 && 'translateY(-110vh)'
            }}
        >
            <div className="anyone-trusted-mob-inner-container" >

                <div className="left-section">
                    <div className="left-content"
                        style={{
                            transform: Page === 2 ? `translateX(${Math.min(0, (Progress * 2))}vw)`: 
                            Page >= 3 && `translateX(0vw)`
                        }}
                    >
                        <img src={leftFrame} alt="" className="content-img" />
                        {/* {Progress.toString()} */}
                    </div>
                </div>
                <div className="right-section">

                    <div className="name-container"
                        style={{
                            transform: Page === 2 ? `translateY(${Math.max(-60, -Progress * 75)}vh)` :
                            Page >= 3 && `translateY(-60vh)`

                        }}
                    >
                        {
                            DATA.map((item, i) => {
                                return (
                                    <div className="name"
                                        key={i}
                                        
                                    >{item.name}</div>
                                )
                            })
                        }
                    </div>
                </div>


            </div>

        </div >
    )
}
