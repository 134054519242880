import React, { useState, useEffect } from "react";

//styles
import "./App.scss";

// components
import PhoneRotationComp from "./components/Phone-Rotation-Comp/phone-rotation-comp";
import HomePage from "./pages/Home-Page/home-page";
import HomePageMob from "./pages/Home-Page-Mob/home-page-mob";

export default function App() {

  let mediaQuery = window.matchMedia(`(max-width: 480px)`);
  const [query, setQuery] = useState(mediaQuery.matches);

  const [dimension, setDimension] = useState({
    hg: window.innerHeight,
    wth: window.innerWidth
  });

  const [ifExceeded, setIfExceeded] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setQuery(mediaQuery.matches)

      setDimension({
        hg: window.innerHeight,
        wth: window.innerWidth
      })

      if (dimension.wth / 1.52 < dimension.hg && dimension.wth > 480) {
        setIfExceeded(true)

      }
      else {
        setIfExceeded(false)
      }
    };

    // Add resize event listener
    window.addEventListener(
      'resize',
      handleResize
    );

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener(
        'resize'
        ,
        handleResize
      );
    };
    // eslint-disable-next-line
  }, [mediaQuery]);


  return (
    <div className="app-main" >

      <div className="phone-rotation-indication-div"
        style={{
          display: !ifExceeded && "none"

        }}
      >
        <PhoneRotationComp />
      </div>

      {
        query ?
          <HomePageMob /> :
          !query &&
          < HomePage />
      }

    </div >
  );
}