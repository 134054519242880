import React, { useState, useEffect } from 'react';

//styles
import './know-anyone.scss';

//imgs
const bgImg = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/questionmark-yellow-black-color.png';
const logoTransparent = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/logo.png';
// const Frame1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame1.png';
// const Frame1_1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame1_1.png';
// const Frame2 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame2.png';
// const Frame2_1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame2_1.png';
// const Frame3 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame3.png';
// const Frame3_1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame3_1.png';
// const Frame4 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame4.png';
// const Frame4_1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame4_1.png';
const QuestionMarkIcon = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/question-mark.png';
const konktHeading = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/konkt-heading.png';


export default function KnowAnyone({ Progress, Page }) {

    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTrigger(true)
        }, 1500);
    }, [])

    const progress = 0.4;
    // const progress1 = 0.9;


    return (
        <div className="know-anyone-main-container"
            style={{
                animation: Page >= 4 && 'knowAnyoneKF 2.8s ease-in-out',
                transform: Page >= 4 && Page < 5 ? 'translateY(-70vh) scale(0.2)' :
                    Page >= 5 && 'translateY(-70vh)rotate(65deg) scale(0.2)',

            }}
        >
            <div className="know-anyone-inner-container"
                style={{
                    background: Page > 1 && 'none'
                }}
            >
                <div className="background-section"
                    style={{
                        opacity: Page === 1 ? `${Math.max(0, (1 - (Progress * 1)))}` :
                            Page >= 2 ? '0' : '0',

                    }}
                >
                    <img src={bgImg} alt="" className="bg-img"
                        style={{
                            transform: trigger && 'scale(1.2)',

                        }}
                    />
                    <div className="top-layer" />
                </div>
                {/* <div className="background-section2"
                    style={{
                        opacity: Page >= 1 && Progress >= 0.5 ? `${Math.min(1, (0 + (Progress * 1)))}` :
                            Page >= 2 ? '1' : '0',

                    }}
                >
                   <p>{`${Math.min(1, (0 + (Progress * 1)))}`}</p>
                </div> */}
                

                <img className="transparent-logo-img"
                    src={logoTransparent}
                    alt=""
                    style={{
                        transform: Page === 1 && trigger && Progress < progress ? `scale(1) translateY(0vh)` :
                            Page === 1 && trigger && Progress > progress ?
                                `scale(${Math.max(0.8, (1 - (Progress * 2)))}) translateY(${Math.max(-100, (-Progress * 100))}vh)` : Page > 1 && 'scale(0.8) translateY(-100vh)',
                        opacity: Page >= 1 && trigger && '0.08',

                    }}
                />
                <img className="konkt-heading-img"
                    src={konktHeading}
                    alt=""
                    style={{
                        transform: Page === 1 && trigger && Progress < progress ? `scale(1) translateY(0vh)` :
                            Page === 1 && trigger && Progress > progress ?
                                `scale(${Math.max(0.8, (1 - (Progress * 2)))}) translateY(${Math.max(-50, (-Progress * 100))}vh)` : Page > 1 && 'scale(0.8) translateY(-40vh)',

                    }}
                />

                {/* ============================================= */}

                <div className="heading-section"
                    style={{
                        transform: Page === 1 && trigger && Progress < progress ? 'translateY(0) scale(1)' :
                            Page === 1 && trigger && Progress > progress ?
                                ` translateY(${Math.max(-65, (-Progress * 60))}vh)` : Page > 1 && ' scale(0.8) translateY(-60vh)',

                    }}
                >
                    <p className="heading-text">
                        Do you know <br />
                        <span className="span1">ANYONE</span>
                    </p>
                    <p className="sub-heading-text">
                        With Conct, this is the last time you ask this question.
                    </p>
                    <span className="question-mark-icon"
                        style={{
                            transform: trigger && 'translate(0vw, 0vh) scale(1)',
                            opacity: trigger && '1'

                        }}
                    >
                        <img src={QuestionMarkIcon} alt="" className="icon-img" />
                    </span>
                </div>

            </div>
        </div >
    )
}
