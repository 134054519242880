import React from 'react';

//styles
import './second-mob-comp.scss';



//imgs
// const bgImg = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/dot-pattern-black-abstract.png';
const CircleGroup = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Group38246.png';


export default function SecondMobComp({ Progress, Page }) {

    // const progress1 = 0.3
    return (
        <div className="second-mob-comp-main-container">
            {/* <img src={bgImg} alt="" className="second-comp-bg-img"
                style={{
                    opacity: Page >= 2 && Page < 3 && '1',
                    transform: Page >= 2 && 'rotate(0deg)'
                }}
            /> */}

            <div className="huge-circle"
                style={{
                    transform: Page === 1 && Progress > 0.5 ? `scale(${Math.max(0.6, (Progress * 0.02))})` :
                        Page > 1 && Page < 3 ? 'scale(1)' :
                            Page >= 3 && Page < 4 && 'translateY(-60vh) scale(0.35)',
                    // Page >= 4 && 'translateY(-120vh) rotate(65deg) scale(0.35)',

                    animation: Page >= 3 && 'knowAnyoneMobKF1 2.8s ease-in-out',
                    background: Page >= 3 && '#FFE500',

                    backdropFilter: 'blur(4px)',

                    opacity: Page >= 4 && '0',
                    display: Page >= 4 && 'none'
                }}
            >
                <img src={CircleGroup} alt="" className="circle-group-img"
                    style={{
                        opacity: Page < 3 && '0'
                    }}
                />
            </div>

        </div>
    )
}

