import React, { useState } from 'react';

// styles
import './anyone-trusted.scss';

//imgs
const leftFrame = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Frame934.png';

export default function AnyoneTrusted({ Progress, Page }) {

    const DATA = [
        { name: '' },
        { name: '' },
        { name: 'Chartered Accountant' },
        { name: 'Designer' },
        { name: 'Doctor' },
        { name: 'Babysitter' },
        { name: 'Lawyer' },
        { name: 'School' },
        { name: 'Connect' },
        { name: '' },
        { name: '' },

    ]

    const [textID, setTextID] = useState(5)

    return (
        <div className="anyone-trusted-main-container"
            style={{
                opacity: Page < 2 && `0`,
                transform: Page >= 3 && 'translateY(-110vh)'
            }}
        >

            <div className="anyone-trusted-inner-container" >

                <div className="left-section">
                    <div className="left-content"
                        style={{
                            transform: Page >= 2 && `translateY(${Math.max(-53, (-Progress * 80))}vh)`
                        }}
                    >
                        <img src={leftFrame} alt="" className="content-img" />
                        {/* {Progress.toString()} */}
                    </div>
                </div>
                <div className="right-section">

                    <div className="name-container"
                        style={{
                            transform: Page >= 2 && `translate(${Math.max(-25, - Progress * 55)}vw,${Math.max(-50, -Progress * 75)}vh)`

                        }}
                    >
                        {
                            DATA.map((item, i) => {
                                return (
                                    <div className="name"
                                        key={i}
                                        style={{
                                            width: i === 2 && '100%',
                                            color: textID === i && '#FFE500'
                                        }}

                                        onMouseOver={() => { setTextID(i) }}
                                        onMouseOut={() => { setTextID(5) }}

                                    >{item.name}</div>
                                )
                            })
                        }
                    </div>
                </div>


            </div>

        </div >
    )
}
