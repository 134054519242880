import React, { useState, useEffect } from 'react';
import Stickyroll from "@stickyroll/react/stickyroll";

//styles
import './home-page.scss';

//components
import KnowAnyone from './Know-Anyone-Comp/know-anyone';
import SecondComp from './SecondComp/second-comp';
import AnyoneTrusted from './Anyone-Trusted/anyone-trusted';
import SearchBar from '../../components/Search-Bar-Comp/search-bar';
import BuildingNetwork from './Building-Network-Comp/building-network';
import LoaderComp from '../../components/Loader-Comp/loader-comp';
import CircleGroupComp from './Circle-Group-Comp/circle-group-comp';

export default function HomePage() {

    const [loader, setLoader] = useState(false)

    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTrigger(true)
        }, 1500);
    }, [])


    const [progress, setProgress] = useState({
        Progress: 0,
        Page: 1
    })
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        setTimeout(() => {
            setLoader(true)
        }, 1500);
    }, [])

    return (
        <div className="home-page-main-container"
            style={{
                backgroundColor: progress.Progress > 0.3 && '#000'

            }}
        >
            <Stickyroll
                pages={8}
                factor={2}
                onProgress={(progress, page) => {
                    setProgress({
                        Progress: progress,
                        Page: page
                    })
                }}
            >

                {/* <p style={{
                    position: 'fixed',
                    color: '#fff',
                    top: '0px',
                    fontSize: '1.2rem',
                    zIndex: '5'
                }}>PAGE:{progress.Page} &nbsp;&nbsp; PROGRESS:{progress.Progress}</p> */}

                <div className="loader-container"
                    style={{
                        display: loader && 'none'
                    }}
                >
                    <LoaderComp />
                </div>

                <div className="main-container">
                    <KnowAnyone
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />
                    <CircleGroupComp
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}

                    />

                    <SecondComp
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />

                    <SearchBar
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />

                    <AnyoneTrusted
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />

                    <BuildingNetwork
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />

                </div>

            </Stickyroll>
        </div>
    )
}
