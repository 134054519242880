import React from 'react';

//styles
import './loader-comp.scss';

// const Frame1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/logo-frame1.png';
// const Frame2 = 'https://i.ibb.co/6rxzvMt/logo-frame2.png';

// const Frame3 = 'https://i.ibb.co/SQmqCWn/logo-frame3.png';

const mainLogo = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/main-logo-vector.png';

// const FrameMask = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/logo-frame-mask.png';


//imgs
const bgImg = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/dot-pattern-black-circle.png';
// const cg1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Group1.png';
// const cg2 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Group2.png';
// const cg3 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Group3.png';


export default function LoaderComp() {
    return (
        <div className="loader-comp-main-container" >
            <img src={bgImg} alt="" className="bg-img" />

            <div className="logo-main-frame">

                {/* <div className="img-frame1"
                    style={{
                        backgroundImage: `url(${Frame1})`
                    }}
                /> */}

                <div className="img-frame1"
                    style={{
                        backgroundImage: `url(${mainLogo})`
                    }}
                />

                {/* <div className="img-frame2"
                    style={{
                        maskImage: `url(${Frame2})`,
                        WebkitMaskImage: `url(${Frame2})`
                    }}
                >
                    <div className="mask-img-div">
                        <img src={FrameMask} alt="" className="frame-img" />
                        <img src={FrameMask} alt="" className="frame-img" />
                        <img src={FrameMask} alt="" className="frame-img" />

                    </div>

                </div>

                <div className="img-frame3"
                    style={{
                        maskImage: `url(${Frame3})`,
                        WebkitMaskImage: `url(${Frame3})`
                    }}
                >
                    <div className="mask-img-div">
                        <img src={FrameMask} alt="" className="frame-img" />
                        <img src={FrameMask} alt="" className="frame-img" />
                        <img src={FrameMask} alt="" className="frame-img" />

                    </div>


                </div> */}


            </div>


        </div >
    )
}



// <div className="img-frame3"
//                     style={{
//                         // backgroundImage: `url(${Frame3})`,
//                         maskImage: `url(${Frame3})`,
//                         WebkitMaskImage: `url(${Frame3})`
//                     }}
//                 >
//                     <img src={FrameMask} alt="" className="frame-img" />
//                     <img src={FrameMask} alt="" className="frame-img" />
//                     <img src={FrameMask} alt="" className="frame-img" />
//                     <img src={FrameMask} alt="" className="frame-img" />


//                 </div>




// <div className="loader-comp-main-container" >

// <img src={bgImg} alt="" className="bg-img" />
// <div className="circle-vector-container">
//     <div className="circle-vector-one circle-vector">
//         <img src={cg3} alt="" className="vector-img" />
//     </div>
//     <div className="circle-vector-two circle-vector">
//         <img src={cg2} alt="" className="vector-img" />
//     </div>
//     <div className="circle-vector-three circle-vector">
//         <img src={cg1} alt="" className="vector-img" />
//     </div>
// </div>

// </div>