import React, { useState, useEffect } from 'react';
import Stickyroll from "@stickyroll/react/stickyroll";

//styles
import './home-page-mob.scss';

//components
import KnowAnyoneMob from './Know-Anyone-Comp/know-anyone-mob';
import SecondMobComp from './Second-Mob-Comp/second-mob-comp';
import AnyoneTrustedMob from './Anyone-Trusted-Mob/anyone-trusted-mob';
import SearchBarMob from '../../components/Search-Bar-Mob-Comp/search-bar-mob';
import BuildingNetworkMob from './Building-Network-Mob-Comp/building-network-mob';
import LoaderComp from '../../components/Loader-Comp/loader-comp';
import CircleGroupMobComp from './Circle-Group-Mob-Comp/circle-group-mob-comp';

export default function HomePageMob() {

    const [loader, setLoader] = useState(false)

    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTrigger(true)
        }, 1500);
    }, [])


    const [progress, setProgress] = useState({
        Progress: 0,
        Page: 1
    })
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        setTimeout(() => {
            setLoader(true)
        }, 1500);
    }, [])

    return (
        <div className="home-page-mob-main-container"
            style={{
                backgroundColor: progress.Progress > 0.3 && '#000'

            }}
        >
            <Stickyroll
                pages={8}
                factor={1}
                onProgress={(progress, page) => {
                    setProgress({
                        Progress: progress,
                        Page: page
                    })
                }}
            >

                {/* <p style={{
                    position: 'fixed',
                    color: '#fff',
                    top: '0px',
                    fontSize: '1.2rem',
                    zIndex: '5'
                }}>PAGE:{progress.Page} &nbsp;&nbsp; PROGRESS:{progress.Progress}</p> */}

                <div className="loader-container"
                    style={{
                        display: loader && 'none'
                    }}
                >
                    <LoaderComp />
                </div>

                <div className="main-container">
                    <KnowAnyoneMob
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />
                    <CircleGroupMobComp
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}

                    />

                    <SecondMobComp
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />


                    <SearchBarMob
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />

                    <AnyoneTrustedMob
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />


                    <BuildingNetworkMob
                        Progress={progress.Progress}
                        Page={progress.Page}
                        Trigger={trigger}
                    />

                </div>

            </Stickyroll>
        </div>
    )
}
