import React from 'react';

//styles
import './building-network.scss';

// import CardOne from '../../../img/Frame941.png';


//imgs
const bgImg = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/dot-pattern-black-abstract.png';
const headingText = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/building-network-text.png';
const bottomImg = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Frame936.png';
const groupImg = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Group38240.png';
const btnLeft = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/button-left.png';
const btnRight = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/button-right.png';
const CardOne = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Frame941.png';
// const CardTwo = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/Frame942.png';
const ourSolutionText = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/our-solution-text.png';
const ourSolutionText2 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/our-solution-text2.png';

const Heading1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/heading1.png';
const Heading2 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/heading2.png';
const Heading3 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/heading-text-img.png';
const connectKonktHeading = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/connect-konkt-heading.png';
const conctLogo = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/conct-logo-comp.png'




export default function BuildingNetwork({ Progress, Page }) {
    return (
        <div className="building-network-main-container"
            style={{
                opacity: Page >= 3 && '1'
            }}
        >
            <div className="building-network-inner-section">

                <div className="building-network-bg">
                    <img src={bgImg} alt="" className="bg-img"
                        style={{
                            animation: Page >= 3 && 'buildingNetworkKF 2.8s ease-in-out',
                            transform: Page >= 3 && 'rotate(0deg)'
                        }}
                    />
                </div>

                <div className="content-container"
                    style={{
                        animation: Page >= 3 && 'imgSectionKF 2.8s ease-in-out',
                        transform: Page >= 3 && 'translateY(0vh)'

                    }}
                >
                    <div className="heading-section"
                        style={{
                            transform: Page >= 4 && `translateY(-80vh)`
                        }}
                    >
                        <img src={headingText} alt="" className="heading-sec-img" />
                    </div>
                    <div className="img-section"
                        style={{
                            transform: Page >= 4 && `translateY(40vh)`

                        }}
                    >
                        <img src={bottomImg} alt="" className="bottom-img" />
                        <img src={ourSolutionText} alt="" className="our-solution-text-img" />
                        <img src={ourSolutionText2} alt="" className="our-solution-text2-img" />

                    </div>

                </div>

                {/* ====================================================================== */}

                <div className="social-networking-container"
                    style={{
                        transform: Page === 4 && Progress < 0.5 ? `translateY(0vh) scale(1)` :
                            Page === 4 && Progress > 0.5 ? `translateY(${Math.max(-120, (-Progress * 40))}vh) scale(${Math.max(0.8, (1 - Progress * 0.1))})` :
                                Page >= 5 && `translateY(-150vh) scale(0.8)`,
                        opacity: Page >= 4 && '1'
                    }}
                >
                    <img src={groupImg} alt="" className="social-networking-img" />
                </div>

                {/* ====================================================================== */}
                <div className="our-konkt-family-container"
                    style={{
                        transform: Page >= 5 && 'translate(0vh)'
                    }}
                >
                    <div className="our-konkt-family-inner-section">

                        <img src={Heading1} alt="" className="top-heading-img"
                            style={{
                                transform: Page === 6 && Progress > 0.5 ? `translateY(${Math.max(-80, (-Progress * 40))}vh)` :
                                    Page >= 7 && 'translateY(-80vh)'
                            }}
                        />
                        {/* <div className="left-card-div"
                            style={{
                                transform: Page === 6 ? 'translate(0%) rotate(0deg)' :
                                    Page === 7 && Progress < 0.5 ? `translate(0%) rotate(0deg)` :
                                        Page === 7 && Progress > 0.5 && Page < 8 ? `translate(${Math.max(-50, (-Progress * 20))}vw) rotate(0deg)` :
                                            Page >= 8 && `translateX(-50vw)`
                            }}
                        >
                            <img src={CardTwo} alt="" className="left-card-img" />
                        </div> */}
                        <div className="right-card-div"
                            style={{
                                transform: Page === 5 ? 'translate(0%) rotate(0deg)' :
                                    Page === 6 && Progress < 0.5 ? `translate(0%) rotate(0deg)` :
                                        Page === 6 && Progress > 0.5 && Page < 7 ? `translate(${Math.min(55, (Progress * 25))}vw) rotate(0deg)` :
                                            Page >= 7 && `translate(55vw)`
                            }}
                        >
                            <img src={CardOne} alt="" className="right-card-img" />
                        </div>

                        <div className='btn-container'
                            style={{
                                transform: Page === 6 && Progress > 0.5 ? `translateY(${Math.max(-80, (-Progress * 40))}vh)` :
                                    Page >= 7 && 'translateY(-80vh)'
                            }}
                        >
                            <div className="left-btn"
                                style={{
                                    transform: Page >= 5 && 'translate(0%)'
                                }}
                            >
                                <div className="btn-inner-div"
                                    style={{
                                        backgroundImage: `url(${btnLeft})`
                                    }}
                                >Tech Team 04</div>
                            </div>
                            <div className="right-btn"
                                style={{
                                    transform: Page >= 5 && 'translate(0%)',

                                }}
                            >
                                <div className="btn-inner-div"
                                    style={{
                                        backgroundImage: `url(${btnRight})`
                                    }}
                                >Non-Tech Team 02</div>
                            </div>
                        </div>


                        <img src={Heading2} alt="" className="bottom-heading"
                            style={{
                                transform: Page >= 6 && Progress > 0.5 ? `translateY(${Math.max(40, (Progress * 10))}vh)` :
                                    Page >= 7 && 'translateY(40vh)'
                            }}
                        />
                    </div>
                </div>

                {/* ====================================================================== */}
                <div className="engage-leverage-konkt-section"
                    style={{
                        transform: Page === 7 && Progress < 0.7 ? `translate(0vh) scale(1)` :
                            Page === 7 && Progress > 0.7 ? `translate(${Math.max(-100, (-Progress * 50))}vw)` :
                                Page >= 8 && 'translate(-120vw)'
                    }}
                >
                    <img src={Heading3} alt="" className="engage-leverage-konkt-heading" />

                    <p className="total-addressable-market-heading">Total &nbsp;Addressable &nbsp;Market</p>
                </div>

                {/* ====================================================================== */}

                <div className="connect-conct-section"
                    style={{
                        animation: Page >= 8 && 'connectKonktHeadingKF 1.8s ease-in-out',
                        transform: Page >= 8 && 'translate(0vw)'
                    }}
                >

                    <div className="connect-conct-inner-section">
                        <img src={conctLogo} alt="" className="conct-logo-img"
                            style={{
                                transform: Page >= 8 && 'translateY(0vw)'
                            }}
                        />
                        <img src={connectKonktHeading} alt="" className="connect-konkt-heading-img" />
                    </div>
                </div>
            </div >
        </div >
    )
}
