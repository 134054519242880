import React, { useState, useEffect } from 'react';

//styles
import './search-bar.scss';

//imgs
const searchIcon = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/search-icon.png';

export default function SearchBar({ Progress, Page }) {

    const [listID, setListID] = useState(0)

    useEffect(() => {
        listID === 0 && setTimeout(() => {
            setListID(1)
        }, 1500);
        listID === 1 && setTimeout(() => {
            setListID(2)
        }, 1500);
        listID === 2 && setTimeout(() => {
            setListID(3)
        }, 1500);
        listID === 3 && setTimeout(() => {
            setListID(4)
        }, 1500);
        listID === 4 && setTimeout(() => {
            setListID(5)
        }, 1500);
        listID === 5 && setTimeout(() => {
            setListID(0)
        }, 1500);


    }, [listID])

    const Data = [
        {
            name: 'Designer'
        },
        {
            name: 'Doctor'
        },
        {
            name: 'Babysitter'
        },
        {
            name: 'Lawyer'
        },
        {
            name: 'School'
        },
        {
            name: 'Connect'
        }
    ]

    // const progress = 0.1;

    const progress1 = 0.3;
    const progress2 = 0.4;

    return (
        <div className="search-bar-main-container"
            style={{
                // transform: Page >= 2 && Page < 3 ? 'scale(1.4)' :
                //     Page >= 2 && `translateY(-100vh)`
                transform: Page === 1 && Progress < progress2 ? 'translateY(0) scale(1)' :
                    Page === 1 && Progress > progress2 ?
                        ` translateY(${Math.max(-80, (-Progress * 60))}vh)` : Page > 1 && 'translateY(-80vh)',

            }}
        >
            <div className="text-div">
                <div className="text-one">You Know Any Trust Worthy</div>&nbsp;
                <div className="text-two">

                    {
                        Data.map((item, i) => {
                            return <span
                                className='name-span'
                                key={i}
                                style={{
                                    transform: i <= listID - 1 ? 'translateY(-120%)' :
                                        listID === i ? 'translateY(0%)' :
                                            i >= listID + 1 && 'translateY(120%)'
                                }}
                            >{item.name}</span>
                        })
                    }

                </div>

            </div>
            <div className="search-btn">
                <div className="icon-div"
                    style={{
                        backgroundImage: `url(${searchIcon})`
                    }}
                />
            </div>

        </div >
    )
}
