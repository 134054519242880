import React, { useState, useEffect } from 'react';

//styles
import './circle-group-mob-comp.scss';



//imgs
// const Frame1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame1.png';
const Frame1_1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame1_1.png';
const Frame2 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame2.png';
const Frame2_1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame2_1.png';
const Frame3 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame3.png';
// const Frame3_1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame3_1.png';
// const Frame4 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame4.png';
const Frame4_1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/frame4_1.png';
const Group1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/level-1.png';
// const Group1_1 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/level-1_1.png';
const Group2 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/level-2.png';
const Group3 = 'https://assets1-bkcuadcwfqfkb5d3.z03.azurefd.net/konkt/konkt-web/home-page-img/level-3.png';



export default function CircleGroupMobComp({ Page, Progress, Trigger }) {
    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setTrigger(true)
        }, 500);
    }, [])

    const progress1 = 0.3;
    const progress2 = 0.4;


    return (
        <div className="circle-group-mob-comp-main-container"
            style={{
                transform: Page === 1 && trigger && Progress < progress2 ? 'translateY(0) scale(1)' :
                    Page === 1 && trigger && Progress > progress2 ?
                        `scale(${Math.max(0.5, (1 - (Progress * 0.8)))}) translateY(${Math.max(-120, (-Progress * 120))}vh)` :
                        Page > 1 && 'scale(0.5) translateY(-120vh)',

                opacity: Page === 1 && Progress > 0.4 ? '0' :
                    Page > 1 && '0',

                display: Page > 1 && 'none'

            }}
        >
            <div className="circle-group-inner-container">

                {/* ================================================================ */}

                <div className="circle-one-section">
                    <div className="circle-one-inner-circle"
                        style={{
                            transform: Page >= 1 && trigger && 'rotate(0deg)',

                        }}
                    >
                        <img src={Frame1_1} alt="" className="icon-two"
                            style={{
                                transform: Page >= 1 && trigger && 'rotate(0deg)'
                            }}
                        />
                        <img src={Frame2} alt="" className="icon-one"
                            style={{
                                transform: Page >= 1 && trigger && 'rotate(0deg)'
                            }}
                        />

                        <div className="level-group3"
                            style={{
                                clip: Trigger && 'rect(0vh, 40vw, 25vh, 0vw)',
                                opacity: Page >= 1 && Progress > progress2 && '0',
                                transition: Page >= 1 && Progress > progress1 && '0.2s ease-in-out'

                            }}
                        >
                            <img src={Group3} alt="" className="level3-img" />
                        </div>

                    </div>

                </div>

                {/* ================================================================ */}

                <div className="circle-two-section">
                    <div className="circle-two-inner-circle"
                        style={{
                            transform: Page >= 1 && trigger && 'rotate(0deg)',

                        }}
                    >
                        <img src={Frame2} alt="" className="icon-one"
                            style={{
                                transform: Page >= 1 && trigger && 'rotate(0deg)'
                            }}
                        />
                        <img src={Frame4_1} alt="" className="icon-two"
                            style={{
                                transform: Page >= 1 && trigger && 'rotate(0deg)'
                            }}
                        />
                        <img src={Frame3} alt="" className="icon-three"
                            style={{
                                transform: Page >= 1 && trigger && 'rotate(0deg)'
                            }}
                        />
                        <div className="level-group2"
                            style={{
                                clip: Trigger && 'rect(0vh, 20vw, 35vh, 0vh)',
                                opacity: Page >= 1 && Progress > progress2 && '0',
                                transition: Page >= 1 && Progress > progress1 && '0.2s ease-in-out'

                            }}
                        >
                            <img src={Group2} alt="" className="level2-img" />
                        </div>

                    </div>
                </div>

                {/* ================================================================ */}

                <div className="circle-three-section">
                    <div className="circle-three-inner-circle"
                        style={{
                            transform: Page >= 1 && trigger && 'rotate(0deg)',
                            backgroundColor: Page === 1 && Progress > progress2 ? '#ffffff00' :
                                Page >= 2 && '#ffffff00'

                        }}
                    >
                        <img src={Frame1_1} alt="" className="icon-one"
                            style={{
                                transform: Page >= 1 && trigger && 'rotate(0deg)',

                            }}
                        />
                        <img src={Frame2_1} alt="" className="icon-two"
                            style={{
                                transform: Page >= 1 && trigger && 'rotate(0deg)',

                            }}
                        />

                        <div className="level-group1"
                            style={{
                                clip: Trigger && 'rect(0px, 18vw, 50vh, 0vw)',
                                opacity: Page >= 1 && Progress > progress2 && '0',
                                transition: Page >= 1 && Progress > progress1 && '0.2s ease-in-out'

                            }}
                        >
                            <img src={Group1} alt="" className="level1-img" />
                        </div>



                    </div>
                </div>

            </div>
        </ div>
    )
}
